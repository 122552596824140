body {
  color: #fff;
  font-size: 1.1rem;
}

h1 {
  padding: 1rem 0;
  display: block;
}

p {
  display: block;
}

a {
  color: #ccc;
}

a:hover {
  color: #fff;
}
